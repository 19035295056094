import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutSection from "../components/aboutSection"

const AboutPage = () => (
  <Layout
    headerImage={(
      <div style={{ backgroundColor: "#4B7E89" }}>
        <div class="pt-4 pb-4">
          <h1 class="h1-text text-center mb-0">About Me</h1>
        </div>
      </div>
    )}
  >
    <SEO title="About" />
    <div class="mt-5" />
    <AboutSection 
      leftImage='about_dev.jpg' 
      title='Development'
      text={
        `I am the Director of Software Engineering at Bytelion. I have worked on various types of projects, producing everything from startup to enterprise solutions. One of my main goals for myself is to grow with my team so we can produce the best solutions possible for our clients.\n\nI enjoy mobile development over any other type of development. Working in React Native is my specialty, although I have worked with Xamarin and Native iOS/Android in the past.\n\nWhen not working on mobile development I enjoy creating API's in Ruby on Rails. I believe it is always beneficial to understand both sides of development in order to provide the best solution when creating a product.`
      }
    />
    <AboutSection 
      rightImage='about_kenz.jpg' 
      title='Home Life'
      text={
        `Outside of development I enjoy spending time with my wife Kenz, family and friends, playing video games, and working on my collection of jigsaw puzzle wall art. \n\nThe more time I can spend outside the better. Camping and hanging out by the river in the summer is one of my favorite things to do. Enjoying a healthy balance of working inside and spending any time I can outside is what I strive for.`
      }
    />
  </Layout>
)

export default AboutPage
