import React from "react"
import { Image } from 'react-bootstrap'
import "./aboutSection.css"

const AboutSection = ({ leftImage, rightImage, title, text }) => {
  return (
    <div class="d-flex mb-3 flex-wrap">
      {leftImage && (
        <Image 
          src={require(`../images/${leftImage}`)}
          className="about-image"
          rounded
        />
      )}
      <div class="about-text">
        <h2>{title}</h2>
        <p class="m-0">{text}</p>
      </div>
      {rightImage && (
        <Image 
          src={require(`../images/${rightImage}`)}
          className="about-image"
          rounded
        />
      )}
    </div>
  )
}

export default AboutSection